/* eslint-disable react/jsx-no-bind */

/* eslint-disable padding-line-between-statements */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormApi } from 'final-form'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import me from '../../../../api/me'
import Button from '../../../../components/Button/Button'
import CenteredLoadingIndicator from '../../../../components/CenteredLoadingIndicator/CenteredLoadingIndicator.component'
import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import { QUERY_KEY_USER_PROFILE } from '../../../../constants'
import FormContainer from '../../../../containers/FormContainer'
import useL3Access from '../../../../hooks/useL3Access'
import useValidators from '../../../../hooks/useValidators'
import { setSpinner } from '../../../../redux/slices/spinnerSlice'
import { IUserInformation } from '../../../../shared/interfaces'
import { FormValues } from '../../../../shared/types'

type Props = {
  data: IUserInformation | undefined
}

const ChangeBasicInformationForm: FunctionComponent<Props> = ({ data }) => {
  const { t } = useTranslation()
  const { isPhoneNumber } = useValidators()
  const hasL3Access = useL3Access()
  const [externalValidationErrors, setExternalValidationErrors] = useState([])
  const dispatch = useDispatch()

  const formRef: React.MutableRefObject<FormApi | null> = useRef(null)

  const changeBasicsMutation = useMutation({
    mutationFn: (values: FormValues) => me.updateBasicUserInfo(values),
  })

  const queryClient = useQueryClient()

  const resetForm = () => formRef?.current?.reset()

  const handleSuccess = async () => {
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_USER_PROFILE] })
    resetForm()
    dispatch(openSnackbar(t('snackbar.basic-info-success'), false))
  }

  useEffect(() => {
    // TODO: Server errors
    if (changeBasicsMutation.status === 'success' && changeBasicsMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))
      setExternalValidationErrors(changeBasicsMutation.data.errors)
    } else if (changeBasicsMutation.status === 'success' && !changeBasicsMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))
      handleSuccess()
    } else if (changeBasicsMutation.status === 'error') {
      dispatch(setSpinner({ visible: false }))
      dispatch(openSnackbar(t('snackbar.basic-info-failed'), true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeBasicsMutation.data, changeBasicsMutation.status])

  // eslint-disable-next-line unicorn/consistent-function-scoping, @typescript-eslint/no-unused-vars
  const onSubmit = async (values: FormValues) => {
    dispatch(setSpinner({ visible: true }))
    changeBasicsMutation.mutate(values)
  }

  return (
    <Container gap="none" padding="none">
      <Container padding="none" margin="none none small none">
        <Heading level="h2" visualLevel="h5" color="bf-blue">
          {t('user-profile.basic-information')}
        </Heading>
      </Container>

      {!data ? (
        <CenteredLoadingIndicator />
      ) : (
        <FormContainer
          onSubmit={onSubmit}
          externalValidationErrors={externalValidationErrors}
          touchEnable={true}
          formRef={formRef}
        >
          <FormContainer.Field
            type="text"
            label={t('microcopies.form-label-firstname')}
            name="firstname"
            value={data?.firstname}
            readOnly={!hasL3Access}
          />
          <FormContainer.Field
            type="text"
            label={t('microcopies.form-label-lastname')}
            name="lastname"
            value={data?.surname}
            readOnly={!hasL3Access}
          />
          <FormContainer.Field
            type={data?.hetu ? 'text' : 'hidden'}
            label={t('microcopies.form-label-ssn')}
            readOnly={true}
            name="hetu"
            value={data?.hetu}
          />
          <FormContainer.Field
            type="tel"
            label={t('microcopies.form-label-phone')}
            name="phone"
            validators={[isPhoneNumber]}
            value={data?.mobile}
            helpText={t('account-creation.form-phone-helptext') || undefined}
            readOnly={!hasL3Access}
            isRequired
          />
          {hasL3Access ? (
            <Button type="submit" label={t('user-profile.basic-information-save')} />
          ) : null}
        </FormContainer>
      )}
    </Container>
  )
}

export default ChangeBasicInformationForm
